
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import Submissions from "./submissions.vue";
import { useStore } from "vuex";
import { AxiosRequestConfig } from "axios";
import { VueEditor } from "vue3-editor";
import { event } from "vue-gtag";

interface Exercise {
  _id: string;
  title: string;
  module: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
  deadline?: string;
  marked: boolean;
  desc: string;
  files: string[];
  photos: string[];
  trimester: string;
  students: [];
  classrooms: string[];
  status: string;
  reviewDate: string;
  reviewNote: string;
  classeRoomNames?: any[];
  teacherName?: string;
}

interface Comment {
  _id: string;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  photo: string;
  text: string;
  user: { _id: string; role: string };
}

export default defineComponent({
  name: "exercise-details",
  components: {
    Submissions,
    VueEditor,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const user = store.getters.currentUser;
    const fullName = user.employee.firstName + " " + user.employee.lastName;

    const status = ref("notValidated");
    const reason = ref("");

    const comments = ref<Comment[]>([]);

    const homeworkID = route.params.id;

    const exercise = ref<Exercise>({
      _id: "",
      subject: "",
      title: "",
      module: "",
      desc: "",
      createdAt: "",
      updatedAt: "",
      deadline: "",
      marked: false,
      files: [],
      photos: [],
      trimester: "",
      students: [],
      classrooms: [],
      status: "",
      reviewDate: "",
      reviewNote: "",
      classeRoomNames: [],
      teacherName: "",
    });

    const studentList = ref<[]>([]);
    const getStudents = (students: [], classrooms: string[]) => {
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = {
        $in: classrooms,
      };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data.filter((s) =>
            students.find((id) => id == s._id)
          );
        })
        .catch((e) => console.log(e));
    };

    ApiService.get(`/lms/homework/${homeworkID}`)
      .then(({ data }) => {
        exercise.value = {
          subject: data.subject.name,
          _id: data._id,
          title: data.title,
          module: data.module,
          desc: data.desc,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          deadline: data.deadline,
          marked: data.marked,
          files: data.resources.filter(
            (f) => !["png", "jpg"].includes(f.split(".")[1])
          ),
          photos: data.resources
            .filter((f) => ["png", "jpg"].includes(f.split(".")[1]))
            .map(
              (p: string) => store.getters.serverConfigUrl.base_url + "/" + p
            ),
          trimester: data.trimester ? data.trimester : "0",
          students: data.students,
          classrooms: data.classrooms,
          status: data.status,
          reviewDate: data.reviewDate,
          reviewNote: data.reviewNote,
          classeRoomNames: data.classeRoomNames,
          teacherName:
            data.teacherName.lastName + " " + data.teacherName.firstName,
        };
        if (data.students && data.students.length != 0)
          getStudents(data.students, data.classrooms);
      })
      .catch((e) => {
        console.log(e);
      });

    ApiService.get(`/lms/comment/homework/${homeworkID}`)
      .then(({ data }) => {
        data.forEach((comment: Comment) => {
          comment.photo = comment.photo
            ? store.getters.serverConfigUrl.base_url +
              "/" +
              comment.photo.replace(/\\/, "/")
            : "media/avatars/blank.png";
          comments.value.push(comment);
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const saveReview = () => {
      Swal.fire({
        title:
          status.value == "rejected"
            ? t(`course.confirmRejectHomework`)
            : t(`course.confirmValidationHomework`),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: status.value == "rejected" ? "#ff3333" : "#47BE7D",
        cancelButtonColor: "#3085d6",
        confirmButtonText:
          status.value == "rejected"
            ? t(`course.acceptReject`)
            : t(`course.acceptValidate`),
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.patch(
            "/lms/advisor/reviewHomework/" + exercise.value._id,
            {
              status: status.value,
              reviewNote: reason.value,
            } as AxiosRequestConfig
          )
            .then(() => {
              event("Review exercice", {
                event_category: "Exercice",
                event_label: "Exercice section",
                value: 1,
              });
              Swal.fire(
                status.value == "rejected"
                  ? t(`course.rejected`)
                  : t(`course.validated`),
                status.value == "rejected"
                  ? t("course.rejectedHomeworkInfo")
                  : t("course.validatedHomeworkInfo"),
                "success"
              ).then(() => {
                exercise.value.status = status.value;
                exercise.value.reviewDate = new Date().toString();
              });
            })
            .catch((e) => console.log(e));
        }
      });
    };

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        [
          "pdf",
          "png",
          "jpg",
          "ppt",
          "xls",
          "txt",
          "gif",
          "doc",
          "pptx",
          "xlsx",
          "docx",
          "zip",
          "mkv",
          "avi",
          "mp4",
          "mp3",
        ].includes(type)
      )
        return type;
      return "file";
    };

    const getFileLink = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      if (fileName.length > 20)
        return (
          "..." + fileName.substring(fileName.length - 20, fileName.length)
        );
      return fileName;
    };

    return {
      t,
      homeworkID,
      exercise,
      saveReview,
      moment,
      getFileIconName,
      getFileLink,
      getShortFileName,
      comments,
      fullName,
      studentList,
      status,
      reason,
    };
  },
});
