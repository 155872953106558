

import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import { useStore } from "vuex";
interface Submission {
  id: string;
  photo?: string;
  fullname: string;
  createdAt: string;
  files?: string[];
  answer?: string;
  color: string;
  status: string;
  note?: number;
  currentNote?: number;
}

export default defineComponent({
  name: "exercise-submissions",
  props: {
    homework: String,
    marked: Boolean,
  },
  components: {},
  setup(props) {
    const store = useStore();

    const { t } = useI18n();
    const buttonNoteRef = ref<null | HTMLButtonElement>(null);
    const submissionUpdate = ref<boolean>(false);

    const list = ref<Submission[]>([]);
    const selectedItem = ref<Submission>({
      id: "",
      fullname: "",
      createdAt: "",
      color: "",
      status: "",
    });

    const validationSchema = Yup.object().shape({
      note: Yup.number()
        .transform((value) => (isNaN(value) ? null : value))
        .min(0, t("course.noteRule"))
        .max(20, t("course.noteRule"))
        .nullable(true),
    });

    const getPhotoURL = (photo: string) => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + photo.replace(/\\/g, "/")
      );
    };

    ApiService.get(`/lms/homework/${props.homework}/submissions`)
      .then(({ data }) => {
        data.forEach((submission) => {
          const sb: Submission = {
            id: submission._id,
            fullname:
              submission.student.firstName + " " + submission.student.lastName,
            files: submission.files,
            answer: submission.answer,
            note: submission.note,
            currentNote: submission.note,

            createdAt: submission.createdAt,
            color: "success",
            status: "submitted",
          };
          if (submission.photo) sb.photo = getPhotoURL(submission.photo);
          list.value.push(sb);
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        [
          "pdf",
          "png",
          "jpg",
          "ppt",
          "xls",
          "txt",
          "gif",
          "doc",
          "pptx",
          "xlsx",
          "docx",
          "zip",
          "mkv",
          "avi",
          "mp4",
          "mp3",
        ].includes(type)
      )
        return type;
      return "file";
    };

    const getFileLink = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      if (fileName.length > 20)
        return (
          "..." + fileName.substring(fileName.length - 20, fileName.length)
        );
      return fileName;
    };

    return {
      buttonNoteRef,
      list,
      selectedItem,
      t,
      validationSchema,
      getFileIconName,
      getFileLink,
      getShortFileName,
      moment,
      submissionUpdate,
    };
  },
});
