import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cbd0e6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "badge-light-primary badge fs-6 fw-bolder my-2 p-5 mx-5" }
const _hoisted_7 = { class: "svg-icon svg-icon-1 svg-icon-primary me-3 lh-0" }
const _hoisted_8 = { class: "card-body pt-0 mt-5" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "svg-icon svg-icon-1" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "d-flex flex-row-fluid flex-wrap align-items-center" }
const _hoisted_13 = { class: "flex-grow-1 me-2" }
const _hoisted_14 = {
  href: "#",
  class: "text-gray-800 fw-bolder text-hover-primary fs-6"
}
const _hoisted_15 = { class: "text-muted fw-bold d-block pt-1" }
const _hoisted_16 = { class: "flex-grow-2 me-2" }
const _hoisted_17 = {
  key: 0,
  class: "badge-light-info badge fs-8 fw-bolder my-2 mx-10"
}
const _hoisted_18 = {
  key: 1,
  class: "badge-light-primary badge fs-8 fw-bolder my-2 mx-10"
}
const _hoisted_19 = { class: "badge-light badge fs-8 fw-bolder p-4 my-2 ms-5" }
const _hoisted_20 = {
  class: "modal fade show",
  tabindex: "-1",
  id: "kt_modal_add",
  "aria-modal": "true",
  role: "dialog",
  ref: "newTargetModalRef"
}
const _hoisted_21 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_22 = { class: "modal-content" }
const _hoisted_23 = { class: "modal-header" }
const _hoisted_24 = { class: "fw-bolder" }
const _hoisted_25 = {
  class: "btn btn-icon btn-sm btn-active-icon-primary",
  id: "kt_modal_add_event_close",
  "data-bs-dismiss": "modal"
}
const _hoisted_26 = { class: "svg-icon svg-icon-1" }
const _hoisted_27 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_28 = { class: "row mb-7" }
const _hoisted_29 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_30 = { class: "col-lg-8" }
const _hoisted_31 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_32 = { class: "row mb-7" }
const _hoisted_33 = { class: "col-lg-4 fw-bold text-muted" }
const _hoisted_34 = { class: "col-lg-8" }
const _hoisted_35 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_36 = { class: "notice d-flex bg-light-info rounded border-gray border border-dashed p-6" }
const _hoisted_37 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_38 = { class: "fw-bold" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = { class: "row mb-7" }
const _hoisted_41 = ["href"]
const _hoisted_42 = ["alt", "src"]
const _hoisted_43 = {
  class: "fw-bold",
  style: {"display":"inline"}
}
const _hoisted_44 = ["href"]
const _hoisted_45 = {
  key: 2,
  class: "notice d-flex bg-light-success rounded border-success border border-dashed p-6"
}
const _hoisted_46 = { class: "svg-icon svg-icon-2tx svg-icon-success me-4" }
const _hoisted_47 = { class: "d-flex flex-stack flex-grow-1" }
const _hoisted_48 = { class: "fw-bold" }
const _hoisted_49 = { class: "text-gray-800 fw-bolder" }
const _hoisted_50 = { class: "fs-6 text-gray-600" }
const _hoisted_51 = { class: "modal-footer" }
const _hoisted_52 = {
  type: "button",
  class: "btn btn-light",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t("course.studentSubmissions")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com010.svg" })
          ]),
          _createTextVNode(_toDisplayString(_ctx.list.length) + " " + _toDisplayString(_ctx.t("course.submissions")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'mb-3': _ctx.list.length - 1 !== index }, "d-flex align-items-sm-center rounded p-4 student-item"]),
          "data-bs-toggle": "modal",
          "data-bs-target": "#kt_modal_add",
          onClick: ($event: any) => (_ctx.selectedItem = item)
        }, [
          _createElementVNode("span", {
            class: _normalizeClass([`svg-icon-${item.color}`, "svg-icon me-5"])
          }, [
            _createElementVNode("span", _hoisted_10, [
              (item.photo)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "w-40px",
                    src: item.photo
                  }, null, 8, _hoisted_11))
                : (_openBlock(), _createBlock(_component_el_avatar, {
                    key: 1,
                    shape: "circle",
                    size: 40,
                    style: {"background-color":"var(--bs-primary)"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.fullname[0].toUpperCase()), 1)
                    ]),
                    _: 2
                  }, 1024))
            ])
          ], 2),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("a", _hoisted_14, _toDisplayString(item.fullname), 1),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t("course.submittedAt")) + ": " + _toDisplayString(_ctx.moment(item.createdAt).format("YYYY/MM/DD, H:mm")), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              (item.note)
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(item.note) + "/20", 1))
                : _createCommentVNode("", true),
              (!item.note)
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.t("course.notNoted")), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", {
                class: _normalizeClass([`badge-light-${item.color}`, "badge fs-8 fw-bolder my-2 mx-10"])
              }, _toDisplayString(_ctx.t(`course.${item.status}`)), 3)
            ]),
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t("course.details")), 1)
          ])
        ], 10, _hoisted_9))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createVNode(_component_el_form, {
            classs: "form fv-plugins-bootstrap5 fv-plugins-framework",
            "label-position": "top",
            "label-width": "200px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("h2", _hoisted_24, _toDisplayString(_ctx.selectedItem.fullname), 1),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("span", _hoisted_26, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.t("course.submissionDate")) + " :", 1),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.moment(_ctx.selectedItem.createdAt).format("YYYY/MM/DD, H:mm")), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.t("course.submissionMethod")) + " :", 1),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.selectedItem.answer ? _ctx.t("course.answer") : _ctx.t("course.files")), 1)
                  ])
                ]),
                (_ctx.selectedItem.answer)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_el_divider, { "content-position": "center" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("course.answer")), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("div", {
                              innerHTML: _ctx.selectedItem.answer,
                              class: "fs-6 text-gray-600"
                            }, null, 8, _hoisted_39)
                          ])
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.selectedItem.files)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_el_divider, { "content-position": "center" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("course.files")), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_40, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItem.files, (file) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: file,
                            class: "d-flex flex-aligns-center pe-10 pe-lg-20"
                          }, [
                            _createElementVNode("a", {
                              target: "_blank",
                              href: _ctx.getFileLink(file)
                            }, [
                              _createElementVNode("img", {
                                alt: _ctx.getShortFileName(file),
                                class: "w-30px h-30px me-2",
                                src: `media/files/${_ctx.getFileIconName(file)}.png`
                              }, null, 8, _hoisted_42)
                            ], 8, _hoisted_41),
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("a", {
                                target: "_blank",
                                href: 
                        _ctx.getFileLink(file)
                      ,
                                class: "fs-6 text-hover-primary fw-bolder"
                              }, _toDisplayString(_ctx.getShortFileName(file)), 9, _hoisted_44)
                            ])
                          ]))
                        }), 128))
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.submissionUpdate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                      _createElementVNode("span", _hoisted_46, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen043.svg" })
                      ]),
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("div", _hoisted_48, [
                          _createElementVNode("h4", _hoisted_49, _toDisplayString(_ctx.t("course.studentSubmission")), 1),
                          _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.t("course.updatedSubmissionMark")), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("button", _hoisted_52, _toDisplayString(_ctx.t("student.close")), 1)
              ])
            ]),
            _: 1
          })
        ])
      ])
    ], 512)
  ]))
}